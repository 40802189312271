//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    user: {
      type: Object,
      required: true
    },
    userNavigation: {
      type: Array,
      default: () => []
    },
    hasLogout: {
      type: Boolean,
      default: () => false
    }
  },
  emits: ['logout-click'],
  setup(_, { emit }) {
    const userNavOpen = ref(false)
    const toggleUserNavOpen = () => { userNavOpen.value = !userNavOpen.value }
    const closeUserNav = () => { userNavOpen.value = false }

    const onLogoutClick = evt => {
      evt.preventDefault()

      emit('logout-click')
    }

    return {
      userNavOpen,
      toggleUserNavOpen,
      closeUserNav,
      onLogoutClick
    }
  },
})
