//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, useContext, useStore, computed } from '@nuxtjs/composition-api'
import useLanguageManager from '~/services/languageManager'

const changeLangForUser = (langManager, auth) => {
  const user = auth.$storage.getState('user')
  if(user) {
    langManager.setLang(user)
  }
}

export default defineComponent({
  middleware: ['auth', 'preventExitPrescriptionFlow'],
  setup() {
    const { $auth } = useContext()
    const langManager = useLanguageManager()

    changeLangForUser(langManager, $auth)

    const store = useStore()
    const currentModal = computed(() => store.getters['modals/current'])

    return {
      currentModal
    }
  }
})
