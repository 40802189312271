const API_DATE_FORMAT = 'YYYY-MM-DD'

export const formatDate = ({ $dayjs }, date, format = null) => {
  if(date) {
    return $dayjs(date).format(format || API_DATE_FORMAT)
  }
}

export const paginatonParams = (rawParams, defaultPageSize = 50) => ({
  page: rawParams.page || 1,
  page_size: rawParams.pageSize || defaultPageSize
})

export const flattenObjectOneLevel = (obj) => {
  const result = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      // Flatten arrays only, keep objects intact
      if (Array.isArray(obj[key])) {
        obj[key].forEach((item, index) => {
          result[`${key}_${index}`] = item;
        });
      } else {
        // Keep the object property intact if it's not an array
        result[key] = obj[key];
      }
    }
  }

  return result;
}

