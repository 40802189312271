import NavigationSteps from '@/services/prescriptionFlow/navigationSteps'
import {
  PROTOTYPE_MODEL_TYPE,
  findPrototypeModel,
  buildPrototypeModel,
  PrototypeModelFromOtherFlow
} from '@/services/prescriptionFlow/prototypeModel'

const getVisibleSteps = steps => steps.filter(step => step.visible)

export const PRESCRIPTION_SCAFFOLD = {
  patient: {},
  diagnostic_material: null,
  working_units: [],
  accessories: [],
  delivery_method: null,
  addresses: {
    delivery: null
  },
  billing_customer: null,
  send_plaster_model: false,
  order_id: null,
  current_step_key: 'heading'
}

export const state = () => ({
  // TODO: extract edit prescription navigation into a indipendent store
  steps: NavigationSteps.getDefaults(),
  prescription: null,
  quotationData: null,
  canExitFromFlow: false,
  hasPendingChanges: false,
  productsDesign: null,
  pendingFilesToAttach: []
})

export const mutations = {
  setEmptyPrescription(state) {
    state.prescription = null
    state.prescription = { ...PRESCRIPTION_SCAFFOLD }
    state.prescription.working_units = []
  },

  setPrescription(state, prescription) {
    state.prescription = { ...PRESCRIPTION_SCAFFOLD, ...prescription }
  },

  resetSteps(state) {
    state.steps = NavigationSteps.getDefaults()
  },

  updateSteps(state, updatedSteps) {
    state.steps = state.steps.map(step => {
      const updatedStep = updatedSteps.find(s => s.key === step.key)
      if (updatedStep) {
        return { ...step, ...updatedStep }
      }

      return { ...step }
    })
  },

  setCurrentStepKey(state, stepKey) {
    state.prescription.current_step_key = stepKey
  },

  setPatient(state, patient) {
    state.prescription.patient = { ...patient }
  },

  setId(state, id) {
    state.prescription.id = id
  },

  setSelectedFlow(state, flow) {
    state.prescription.selected_flow = flow
  },

  setDiagnosticMaterial(state, diagnosticMaterial) {
    state.prescription.diagnostic_material = diagnosticMaterial
  },

  updateDiagnosticMaterial(state, diagnosticMaterial) {
    state.prescription.diagnostic_material = {
      ...state.prescription.diagnostic_material,
      ...diagnosticMaterial
    }
  },

  setSendPlasterModel(state, sendPlasterModel) {
    state.prescription.send_plaster_model = sendPlasterModel
  },

  updateStatus(state, newStatus) {
    state.prescription.status = newStatus
  },

  addWorkingUnit(state, workingUnit) {
    state.prescription.working_units.push({
      ship_when_ready: false,
      unsafe_configuration: false,
      extra_assets: [],
      ...workingUnit
    })
  },

  removeWorkingUnit(state, workingUnit) {
    state.prescription.working_units = [...state.prescription.working_units].filter(({ id }) => id !== workingUnit.id)
  },

  setProjectAsset(state, { projectAsset, workingUnitId }) {
    state.prescription.working_units.forEach(workingUnit => {
      if(!!projectAsset.fileId
          && workingUnit.id === workingUnitId
          && projectAsset.fileId === workingUnit.project_asset.fileId) {
        delete projectAsset.fileId
        workingUnit.project_asset = { ...projectAsset }
      }
    })
  },

  updatePrototypeProjectAsset(state, { prototypeProjectAsset, workingUnitId }) {
    state.prescription.working_units.forEach(workingUnit => {
      if(workingUnit.id === workingUnitId) {
        workingUnit.project_assets = workingUnit.project_assets.map(asset => {
          if (!!prototypeProjectAsset.fileId && asset.fileId === prototypeProjectAsset.fileId) {
            delete prototypeProjectAsset.fileId
            return prototypeProjectAsset  
          }

          return asset
        })
      }
    })
  },

  updateExtraAsset(state, { extraAsset, workingUnitId }) {
    state.prescription.working_units.forEach(workingUnit => {
      if(workingUnit.id === workingUnitId) {
        workingUnit.extra_assets = workingUnit.extra_assets.map(existingExtraAsset => {
          if (!!extraAsset.fileId && existingExtraAsset.fileId === extraAsset.fileId) {
            delete extraAsset.fileId
            return extraAsset  
          }

          return existingExtraAsset
        })
      }
    })
  },
  
  markWorkingUnitCompleted(state, workingUnitId) {
    state.prescription.working_units = [...state.prescription.working_units].map(workingUnit => {
      if(workingUnit.id === workingUnitId) {
        return { ...workingUnit, draft: false, elements: [...workingUnit.elements] }
      }

      return workingUnit
    })
  },

  updateWorkingUnit(state, updatedWorkingUnit) {
    state.prescription.working_units = [...state.prescription.working_units].map(workingUnit => {
      if(updatedWorkingUnit.id === workingUnit.id)
        return { ...workingUnit, ...updatedWorkingUnit }
      
      return workingUnit
    })
  },

  setPrototypeModel(state, prototypeModel) {
    if (prototypeModel) {
      state.prescription.working_units.push(prototypeModel)
    }
    else {
      state.prescription.working_units = state.prescription.working_units
                                                           .filter(({ type }) => type !== PROTOTYPE_MODEL_TYPE)
    }
  },

  addPrototypeModelElement(state, element) {
    state.prescription.working_units.forEach(workingUnit => {
      if (workingUnit.type === PROTOTYPE_MODEL_TYPE) {
        workingUnit.elements.push({ ...element })
      }
    })
  },

  removePrototypeModelElement(state, element) {
    state.prescription.working_units.forEach(workingUnit => {
      if (workingUnit.type === PROTOTYPE_MODEL_TYPE) {
        workingUnit.elements = [...workingUnit.elements].filter(({ id }) => (  id !== element.id ))
      }
    })
  },

  updatePrototypeModel(state, prototypeModel) {
    state.prescription.working_units = state.prescription.working_units.map(workingUnit => {
      if (workingUnit.type === PROTOTYPE_MODEL_TYPE) {
        return  { ...prototypeModel }
      }

      return workingUnit
    })
  },

  resetPrototypeModelConfiguration(state) {
    state.prescription.working_units = state.prescription.working_units.map(workingUnit => {
      if (workingUnit.type === PROTOTYPE_MODEL_TYPE) {
        return  {
          ...workingUnit,
          configured_product: undefined,
          configuration: {},
          project_assets: [],
          extra_assets: []
        }
      }

      return workingUnit
    })
  },

  setDeliveryAddress(state, deliveryAddress) {
    state.prescription.addresses.delivery = { ...deliveryAddress }
  },

  setBillingCustomer(state, billingCustomer) {
    state.prescription.billing_customer = { ...billingCustomer }
  },

  setDeliveryMethod(state, deliveryMethod) {
    state.prescription.delivery_method = deliveryMethod
  },

  setQuotationData(state, quotationData) {
    state.quotationData = quotationData ? { ...quotationData } : null
  },

  setOrderId(state, orderId) {
    state.prescription.order_id = orderId
  },

  nextStep(state) {
    const visibleSteps = getVisibleSteps(state.steps)
    const currentVisibleStepIdx = visibleSteps.findIndex(({ selected }) => selected)
    const nextVisibleStep = visibleSteps[currentVisibleStepIdx + 1]

    state.steps.forEach(step => {
      if (step.key === visibleSteps[currentVisibleStepIdx].key) {
        step.status = 'complete'
        step.selected = false
      }
      else if (nextVisibleStep && step.key === nextVisibleStep.key) {
        step.status = 'current'
        step.selected = true
      }
    })

    return nextVisibleStep && nextVisibleStep.key
  },

  previousStep(state) {
    const visibleSteps = getVisibleSteps(state.steps)
    const currentVisibleStepIdx = visibleSteps.findIndex(({ selected }) => selected)
    const previousVisibleStep = visibleSteps[currentVisibleStepIdx - 1]

    state.steps.forEach(step => {
      if (step.key === visibleSteps[currentVisibleStepIdx].key) {
        step.status = 'upcoming'
        step.selected = false
      }
      else if (previousVisibleStep && step.key === previousVisibleStep.key) {
        step.status = 'current'
        step.selected = true
      }
    })

    return previousVisibleStep && previousVisibleStep.key
  },

  setStepWithKey(state, key) {
    const currentStepIndex = state.steps.findIndex(step => step.key === key)
    state.steps[currentStepIndex].status = 'current'
    state.steps[currentStepIndex].selected = true

    for(let i = 0; i < currentStepIndex; i++) {
      state.steps[i].status = 'complete'
      state.steps[i].selected = false
    }

    for(let i = currentStepIndex + 1; i < state.steps.length; i++) {
      state.steps[i].status = 'upcoming'
      state.steps[i].selected = false
      state.steps[i].clickable = false
    }
  },
  
  setStepClicableWithKey(state, { key, clickable }) {
    if(key === 'heading') return
    
    const currentStepIndex = state.steps.findIndex(step => step.key === key)
    state.steps[currentStepIndex].clickable = clickable
  },

  setSelectedStepWithKey(state, key) {
    state.steps.forEach(step => {
      step.selected = false
      step.clickable = (step.status === 'current') ? true : step.clickable
    })

    const currentStepIndex = state.steps.findIndex(step => step.key === key)
    state.steps[currentStepIndex].selected = true
  },

  setStepVisibleWithKey(state, key) {
    state.steps.forEach(step => {
      if (step.key === key) {
        step.visible = true
      }
    })
  },

  setCanExitFlow(state, newFlag) {
    state.canExitFromFlow = newFlag
  },

  setHasPendingChanges(state, newFlag) {
    state.hasPendingChanges = newFlag
  },

  setProductsDesign(state, newProductsDesign) {
    state.productsDesign = newProductsDesign
  },

  updateAnnotation(state, newAnnotation) {
    state.prescription.annotation = newAnnotation
  },
  
  addPendingFilesToAttach(state, newPendingFile) {
    state.pendingFilesToAttach.push(newPendingFile)
  },

  clearPendingFilesToAttach(state) {
    state.pendingFilesToAttach = []
  }
}

export const actions = {
  initFlow({ commit }) {
    commit('setEmptyPrescription')
    commit('resetSteps')
    commit('setCanExitFlow', false)
    commit('setQuotationData', null)
    commit('setOrderId', null)
    commit('setProductsDesign', null)
    commit('clearPendingFilesToAttach')
  },

  selectedFlow({ commit }, flow) {
    commit('setSelectedFlow', flow)
    commit('setSendPlasterModel', NavigationSteps.shouldSendPlasterModel(flow))
    commit('setPrototypeModel', NavigationSteps.hasPrototypeModel(flow) ? buildPrototypeModel() : undefined)
  },

  makePreviousStepsClickable({ commit, state }, currentStepKey) {
    const currentStepIndex = state.steps.findIndex(step => step.key === currentStepKey)
    
    for(let i = 0; i < currentStepIndex; i++) {
      commit('setStepClicableWithKey', {
        key: state.steps[i].key,
        clickable: NavigationSteps.isStepClickable(state.steps[i].key, state.prescription)
      })
    }
  },

  setNextStep({ commit, state }) {
    commit('updateSteps', NavigationSteps.stepsVisibilityForPrescription(state.prescription))

    const nextStepKey = commit('nextStep')
    commit('setCurrentStepKey', nextStepKey)
  },

  startDesign({ commit }) {
    commit('updateStatus', 'designing')
    NavigationSteps.stepsBeforeProductsDesign()
                   .forEach(step => {
                     commit('setStepClicableWithKey', { key: step.key, clickable: false })
                   })
  },

  removePrototypeModelFromOtherFlow({ commit, state }) {
    commit('setPrototypeModel', undefined)
    commit('updateSteps', NavigationSteps.stepsVisibilityForPrescription(state.prescription))
    commit('setStepWithKey', 'prototype_model_ask')
    commit('setCurrentStepKey', 'prototype_model_ask')
  },

  addPrototypeModelToOtherFlow({ commit, state }) {
    commit('setPrototypeModel', new PrototypeModelFromOtherFlow(state.prescription).build())
    commit('updateSteps', NavigationSteps.stepsVisibilityForPrescription(state.prescription))
    commit('setStepWithKey', 'analogues_configurator')
    commit('setCurrentStepKey', 'analogues_configurator')
  },

  removeWorkingUnitAndAlignSteps({ commit, state }, workingUnit) {
    commit('removeWorkingUnit', workingUnit)

    if (state.prescription.working_units.length === 0) {
      const currentStep = state.steps.find(({ status }) => status === 'current')
      if(currentStep.key !== 'oral_cavity_description') {
        commit('setStepWithKey', 'oral_cavity_description')
        commit('setCurrentStepKey', 'oral_cavity_description')
      }
    }
  },

  addWorkingUnitAndAlignSteps({ commit, state}, workingUnit) {
    commit('addWorkingUnit', workingUnit)
    commit('markWorkingUnitCompleted', workingUnit.id)
    commit('setHasPendingChanges', false)

    const currentStep = state.steps.find(({ status }) => status === 'current')
    if(currentStep.key !== 'oral_cavity_description') {
      commit('setStepWithKey', 'oral_cavity_description')
      commit('setCurrentStepKey', 'oral_cavity_description')
    }
  },

  addPrototypeModelElementAndAlignSteps({ commit, state}, element) {
    commit('addPrototypeModelElement', element)
    commit('setHasPendingChanges', false)
    commit('resetPrototypeModelConfiguration')

    const currentStep = state.steps.find(({ status }) => status === 'current')
    if(currentStep.key !== 'oral_cavity_description') {
      commit('setStepWithKey', 'oral_cavity_description')
      commit('setCurrentStepKey', 'oral_cavity_description')
    }
  },

  removePrototypeModelElementAndAlignSteps({ commit, state }, element) {
    commit('removePrototypeModelElement', element)
    commit('resetPrototypeModelConfiguration')

    const prototypeModel = findPrototypeModel(state.prescription)
    const describedElements = prototypeModel.elements.filter(element => !!element.tipologia)
    if (prototypeModel && describedElements.length === 0) {
      commit('updatePrototypeModel', { ...prototypeModel, elements: [] })
    }

    const currentStep = state.steps.find(({ status }) => status === 'current')
    if(currentStep.key !== 'oral_cavity_description') {
      commit('setStepWithKey', 'oral_cavity_description')
      commit('setCurrentStepKey', 'oral_cavity_description')
    }
  },

  alignSteps({ commit, state }) {
    const selectedStep = state.steps.find(({ selected }) => selected)
    commit('setStepWithKey', selectedStep.key)
    commit('setCurrentStepKey', selectedStep.key)
  },

  clearPrototypeModelConfigurationAndAlignSteps({ commit, state }) {
    commit('resetPrototypeModelConfiguration')
    const selectedStep = state.steps.find(({ selected }) => selected)
    commit('setStepWithKey', selectedStep.key)
    commit('setCurrentStepKey', selectedStep.key)
  },

  addOrUpdateWorkingUnit({ commit, state }, workingUnit) {
    const existingWorkingUnit = state.prescription.working_units.find(({ id }) => workingUnit.id === id)
    if (existingWorkingUnit) {
      commit('updateWorkingUnit', workingUnit)
    }
    else {
      commit('addWorkingUnit', workingUnit)
    }
  },

  orderConfirmed: ({ commit }, orderId) => {
    commit('setOrderId', orderId)
    commit('nextStep')
  },

  processPendingFilesToAttach: ({ commit, state }) => {
    state.pendingFilesToAttach.forEach(({ mutation, data }) => { commit(mutation, data) })
    commit('clearPendingFilesToAttach')
  }
}

export const getters = {
  visibleSteps: ({ steps }) => getVisibleSteps(steps),

  currentStep: ({ steps }) => (
    steps.find(({ selected }) => selected) || steps.find(({ key }) => key === 'confirm')
  ),

  lastNavigableStep: ({ steps }) => steps.find(({ status }) => status === 'current'),

  visibleNextStep: ({ steps }) => {
    const visibleSteps = getVisibleSteps(steps)
    const selectedIdx = visibleSteps.findIndex(({ selected }) => selected)
    return visibleSteps[selectedIdx + 1]
  },

  current: (state) => state.prescription,

  prototypeModel: (state) => findPrototypeModel(state.prescription),

  currentQuotationData: (state) => state.quotationData,

  canExitFromFlow: (state) => !['draft', 'designing'].includes(state.prescription.status) || state.canExitFromFlow,

  hasPendingChanges: (state) => state.hasPendingChanges,

  productsDesign: (state) => state.productsDesign,

  pendingFilesToAttach: (state) => state.pendingFilesToAttach
}
