import Vue from 'vue'

export default new Vue()

export const EVENTS = {
  FILE_UPLOADED: 'file-uploaded',
  LEGAL_CONDITIONS_ORDER_CREATE_ACCEPTED: 'legal-conditions-order-create-accepter',
  MODAL_CONFIRM_YES_CLICK: 'modal-confirm-yes-click',
  MODAL_CONFIRM_NO_CLICK: 'modal-confirm-no-click',
  PREVENT_STEP_NAVIGATION_CONFIRM_EVENT: 'prevent-step-navigation-confirm',
  PREVENT_STEP_BUTTON_NAVIGATION_CONFIRM_EVENT: 'prevent-step-button-navigation-confirm',
  ADD_WORKGROUP_RELATION_SENT_AND_WAIT: 'add-workgroup-relation-sent-and-wait',
  ADD_WORKGROUP_RELATION_SENT_AND_KEEP_EDIT: 'add-workgroup-relation-sent-and-keep-edit',
  SWM_PROJECT_FIELD_SELECTED: 'swm-project-field-selected'
}
