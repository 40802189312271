const API_ERRORS_TO_EXCLUDE = [401, 404]

export default function ({ $axios, $sentry }) {
  $axios.onError(error => {
    // intercept API call error and push to sentry
    if (error.status >= 500 || !API_ERRORS_TO_EXCLUDE.includes(error.status)) {
      $sentry.captureException(error)
    }
  })
}
