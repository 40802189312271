//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, computed, useContext, ref, useRouter } from '@nuxtjs/composition-api'
import useLanguageManager from '~/services/languageManager'

const FLAGS_CODE = {
  it: 'it',
  en: 'gb',
  es: 'es',
  fr: 'fr',
  pt: 'pt'
}

export default defineComponent({
  name: 'LangSwitcher',
  setup() {
    const { i18n, switchLocalePath } = useContext()
    const availableLocales = computed(() => i18n.locales)
    const currentLocaleCode = computed(() => i18n.locale)
    const currentLocaleName = computed(() => 
      availableLocales.value.find(({ code }) => code === currentLocaleCode.value).abbr
    )
    const currentLocaleFlag = computed(() => FLAGS_CODE[currentLocaleCode.value])

    const router = useRouter()
    const languageManager = useLanguageManager()
    const changeLocalePath = (langCode) => {
      languageManager.setFavouriteLang(langCode)
      router.push(switchLocalePath(langCode))
    }

    const langChooserOpen = ref(false)
    const toggleLangChooserOpen = () => { langChooserOpen.value = !langChooserOpen.value }
    const closeLangChooser = () => { langChooserOpen.value = false }

    return {
      availableLocales,

      currentLocaleCode,
      currentLocaleName,
      currentLocaleFlag,

      changeLocalePath,
      langChooserOpen,
      toggleLangChooserOpen,
      closeLangChooser
    }
  }
})
