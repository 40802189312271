//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, defineComponent, useStore } from '@nuxtjs/composition-api'

export default defineComponent({
  setup() {
    const store = useStore()
    const uploaderFiles = computed(() => store.getters['file_uploads/allFiles'])
    const uploadingFiles = computed(() => store.getters['file_uploads/uploadingFiles'])
    const failedFiles = computed(() => store.getters['file_uploads/failedFiles'])
    const attachedFiles = computed(() => store.getters['file_uploads/attachedFiles'])
    const readyFiles = computed(() => store.getters['file_uploads/readyFiles'])

    const totalProgressPercent = computed(() => uploaderFiles.value.reduce((acc, file) => {
      acc += file.progress
      return acc
    }, 0))

    const totalTarget = computed(() => uploaderFiles.value.length * 100)

    return {
      uploadingFiles,
      uploaderFiles,
      failedFiles,
      attachedFiles,
      readyFiles,
      totalTarget,
      totalProgressPercent
    }
  },
})
