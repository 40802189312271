//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  middleware: ['auth'],
  setup() {},
})
