export const FIELDS_DESCRIPTOR =  {
  'tecnica': { restricted: true, header: true },
  'materiale': { header: true, extraInfo: true },
  'materia_prima': { header: true, extraInfo: true },
  'indicazioni_uso': { autocomplete: true, header: true, restrictionsIssuer: true },
  'colore': { header: true },
  'sistematica': {},
  'impianto': {},
  'piattaforma': {},
  'collo': {},
  'chiusura': {},
  'tipo_scan_abutment': {},
  'scan_abutment': { autocomplete: true },
  'foro': {},
  'battuta': {},
  'vite': {}
}