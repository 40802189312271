//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, defineComponent } from '@nuxtjs/composition-api'

const NOTIFICATION_TYPE_ICONS = {
  success: ['fas', 'check-circle'],
  danger: ['fas', 'exclamation-triangle'],
  info: ['fas', 'info-circle']
}

const NOTIFICATION_TYPE_COLORS = {
  success: {
    icon: 'text-green-600',
    border: 'border-green-200',
    background: 'bg-green-50' 
  },
  danger: {
    icon: 'text-red-600',
    border: 'border-red-200',
    background: 'bg-red-50'
  },
  info: {
    icon: 'text-blue-600',
    border: 'border-blue-200',
    background: 'bg-blue-50'
  }
}

export default defineComponent({
  props: {
    title: {
      type: String,
      default: () => null
    },
    text: {
      type: String,
      required: true
    },
    notificationType: {
      type: String,
      default: () => 'success'
    }
  },
  emits: ['close'],
  setup(props, { emit }) {
    const onCloseClick = () => { emit('close') }

    const icon = computed(() => NOTIFICATION_TYPE_ICONS[props.notificationType])
    const colors = computed(() => NOTIFICATION_TYPE_COLORS[props.notificationType])
  
    return {
      onCloseClick,
      icon,
      colors
    }
  },
})
