export const FIELDS_DESCRIPTOR = {
  'tecnica': { restricted: true, header: true },
  'materiale': { extraInfo: true, header: true },
  'materia_prima': { extraInfo: true, header: true },
  'indicazioni_uso': { autocomplete: true, header: true, restrictionsIssuer: true  },
  'colore': { header: true },
  'sistematica': {},
  'impianto': {},
  'piattaforma': {},
  'collo': {},
  'chiusura': {},
  'tipo_scan_abutment': {},
  'scan_abutment': { autocomplete: true },
  'foro': {},
  'interfaccia': {},
  'includere_cappetta': {},
  'cappetta': {},
  't_connect': {},
  'angolazione': {},
  'h_cono': {},
  'transmucosa': {},
  'codice_interfaccia': {},
  'vite': {}
}
