import { formatDate, paginatonParams } from '../commonHelpers'

const notDraftWorkingUnits = workingUnits => workingUnits.filter(({ draft }) => !draft)

export const sanitizedPrescription = prescription => {
  const workingUnits = notDraftWorkingUnits(prescription.working_units)

  return { ...prescription, working_units: [ ...workingUnits ] }
}

export const buildSearchParams = (ctx, params) => ({
  patient: params.patient,
  order_number: params.orderNumber,
  human_identifier: params.humanIdentifier,
  start_date: formatDate(ctx, params.startDate),
  end_date: formatDate(ctx, params.endDate),
  ...paginatonParams(params)
})
