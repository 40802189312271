import { render, staticRenderFns } from "./app.vue?vue&type=template&id=4acc6250&"
import script from "./app.vue?vue&type=script&lang=js&"
export * from "./app.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppNavbar: require('/d3_frontend/components/AppNavbar.vue').default,NotificationsWrapper: require('/d3_frontend/components/NotificationsWrapper.vue').default,Footer: require('/d3_frontend/components/Footer.vue').default})
