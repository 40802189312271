import { v4 as uuidv4 } from 'uuid'
import { TIPOLOGIE_SINGOLE } from '~/working-unit-form/src/helpers/workingUnit'
import { PrototypeModelElementsConfiguratorFields } from '~/components/editPrescription/prototypeModel/ElementsForm'

export const PROTOTYPE_MODEL_TYPE = 'prototype_model'

export const findPrototypeModel = prescription => (
  prescription.working_units.find(({ type }) => type === PROTOTYPE_MODEL_TYPE)
)

export const buildPrototypeModel = () => ({
  type: PROTOTYPE_MODEL_TYPE,
  id: uuidv4(),
  extra_assets: [],
  project_assets: [],
  elements: []
})

export const buildElement = (elemento, values = {}) => ({
  id: uuidv4(),
  elemento,
  draft: true,
  saved: false,
  includi_gengiva: false,
  includi_analogo: false,
  includi_moncone: false,
  ...values
})

const TIPOLOGIA_ACCESSORIES = {
  [TIPOLOGIE_SINGOLE.supporto_implantare]: ['includi_analogo', 'includi_gengiva'],
  [TIPOLOGIE_SINGOLE.dente_preparato]: ['includi_moncone'],
  unset: ['includi_gengiva', 'includi_moncone']
}

export const accessoriesForElement = element => {
  const tipologia = element.tipologia || 'unset'
  return TIPOLOGIA_ACCESSORIES[tipologia]
}

export const ACCESSORIES = ['includi_analogo', 'includi_gengiva', 'includi_moncone']

const ONLY_PRODUCTION_KEY = 'S_PROD'

export const mustUploadProjectAsset = (configuration) => {
  if (!configuration || !configuration.progettazione) return false

  return configuration.progettazione === ONLY_PRODUCTION_KEY
}

export const mustUploadAssets = (configuration) => {
  if (!configuration || !configuration.progettazione) return false

  return configuration.progettazione !== ONLY_PRODUCTION_KEY
}

export class PrototypeModelFromOtherFlow {
  constructor(prescription) {
    this.prescription = prescription
    this.fieldsToExtract = Object.keys(new PrototypeModelElementsConfiguratorFields().describeSegmentFields)
  }

  build() {
    const prototypeModel = buildPrototypeModel()
    prototypeModel.elements.push(...this.#elementsFromWorkingUnits)
    return prototypeModel
  }

  get #elementsFromWorkingUnits() {
    return this.prescription
               .working_units
               .map(workingUnit => this.#elementsFromWorkingUnit(workingUnit))
               .flat()
  }

  #elementsFromWorkingUnit(workingUnit) {
    const tipologia = workingUnit.header.tipologia
    return workingUnit.elements
      .filter(el => !el.type)
      .map(element => {
        const baseConfiguration = this.#elementBaseConfiguration(element)
        const prototypeElement = buildElement(element.elemento, { tipologia, ...baseConfiguration })
        if (prototypeElement.tipologia === TIPOLOGIE_SINGOLE.dente_preparato) {
          prototypeElement.saved = true
        }
        return this.#elementWithDefaultAccessories(prototypeElement)
      })
  }

  #elementBaseConfiguration(workingUnitElement) {
    return Object.keys(workingUnitElement)
                 .filter(field => this.fieldsToExtract.includes(field))
                 .reduce((acc, field) => {
                   acc[field] = workingUnitElement[field]
                   return acc
                 }, {})
  }

  #elementWithDefaultAccessories(element) {
    accessoriesForElement(element).forEach(accessory => { element[accessory] = true })
    return element
  }
}
