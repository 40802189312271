export const state = () => ({
  current: null
})

export const mutations = {
  setCurrentModal(state, { modalName, attrs }) {
    state.current = { name: modalName, attrs }
  },

  removeCurrentModal(state, modalName) {
    const current = state.current || {}
    if(current.name === modalName) {
      state.current = null
    }
  }
}

export const actions = {
  open({ commit }, data) {
    commit('setCurrentModal', { ...data })
  },

  close({ commit }, modalName) {
    commit('removeCurrentModal', modalName)
  }
}

export const getters = {
  current: (state) => state.current
}
