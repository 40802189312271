export const FIELDS_DESCRIPTOR = {
  progettazione: {},
  estensione: {},
  includi_antagonista: {},
  includi_gengiva: {},
  numero_gengive: { autocomplete: true },
  tipo_gengiva: {},
  articolatore: {},
  tipo_stampa: {}
}
