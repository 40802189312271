//
//
//
//
//
//
//
//

import { defineComponent, computed } from '@nuxtjs/composition-api'

const COLORS = {
  'primary': 'bg-primary-100 text-primary-800 border border-primary-200',
  'gray': 'bg-gray-100 text-gray-800 border-gray-200 border'
}

const SIZES = {
  'normal': 'h-9 w-9',
  'small': 'h-7 w-7 text-sm',
  'large': 'h-12 w-12 text-xl'
}

export default defineComponent({
  props: {
    fullName: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: () => 'primary'
    },
    size: {
      type: String,
      default: () => 'normal'
    }
  },
  setup(props) {
    const userNameAbbr = computed(() => {
      const [firstName, ...rest] = props.fullName.split(' ')
      const lastName = rest[rest.length - 1]
      return [firstName, lastName].filter(str => str)
                                  .map(str => str.toUpperCase().charAt(0))
                                  .join('')
    })

    const avatarColor = computed(() => COLORS[props.color])
    const avatarSize = computed(() => SIZES[props.size])

    return {
      userNameAbbr,
      avatarColor,
      avatarSize
    }
  },
})
