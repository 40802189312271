import { render, staticRenderFns } from "./AppNavbar.vue?vue&type=template&id=2a2ae794&"
import script from "./AppNavbar.vue?vue&type=script&lang=js&"
export * from "./AppNavbar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseButton: require('/d3_frontend/base-tailwindcss-components/src/Button.vue').default,FileUploadNavbarProgress: require('/d3_frontend/components/fileUpload/NavbarProgress.vue').default,LangSwitcher: require('/d3_frontend/components/LangSwitcher.vue').default,BaseUserNavbarMenu: require('/d3_frontend/base-tailwindcss-components/src/UserNavbarMenu.vue').default,BaseAvatarImage: require('/d3_frontend/base-tailwindcss-components/src/AvatarImage.vue').default})
