import { withVersion } from '../config'
import { errorHandler } from '../errors'
import { sanitizedPrescription, buildSearchParams } from './helpers'
import deserialize from './deserializer'

const getAllPath = ({ $config }) => withVersion('prescriptions', $config)
const createPath = ({ $config }) => withVersion('prescriptions', $config)
const getItemPath = (id, { $config }) => withVersion(`prescriptions/${id}`, $config)
const getProductionOrdersPath = (id, { $config }) => withVersion(`prescriptions/${id}/production_orders`, $config)
const getShipmentTrackingsPath = (id, { $config }) => withVersion(`prescriptions/${id}/shippings`, $config)
const updatePath = (id, { $config }) => withVersion(`prescriptions/${id}`, $config)
const createQuotationPath = (id, { $config }) => withVersion(`prescriptions/${id}/create_quotation`, $config)
const createOrderPath = (id, { $config }) => withVersion(`prescriptions/${id}/create_order`, $config)
const discardQuotationPath = (id, { $config }) => withVersion(`prescriptions/${id}/discard_quotation`, $config)
const getQuotationPath = (id, { $config }) => withVersion(`prescriptions/${id}/read_quotation`, $config)
const cancelPath = (id, { $config }) => withVersion(`prescriptions/${id}/cancel`, $config)
const duplicatePath = (id, { $config }) => withVersion(`prescriptions/${id}/duplicate`, $config)
const editActivePath = (id, { $config }) => withVersion(`prescriptions/${id}/edit`, $config)
const startProductsDesignPath = (id, { $config }) => withVersion(`prescriptions/${id}/start_products_design`, $config)
const enablePrescriptionEditingByDesignersPath = (id, { $config }) => withVersion(`prescriptions/${id}/enable_prescription_editing_by_designers`, $config)

const getProductsDesignPath = (id, { $config }) => withVersion(`prescriptions/${id}/products_design`, $config)
const getCountPath = ({ $config }) => withVersion('prescriptions/count', $config)

export default (ctx) => ({
  getAllDraft: (params = {}) => () => {
    const { $axios } = ctx
    const _params = { statuses: 'draft,quoted', ...buildSearchParams(ctx, params) }

    return $axios.get(getAllPath(ctx), { params: _params }).catch(errorHandler)
  },

  getAllClosed: (params = {}) => () => {
    const { $axios } = ctx
    const _params = { statuses: 'closed,shipped', ...buildSearchParams(ctx, params) }

    return $axios.get(getAllPath(ctx), { params: _params }).catch(errorHandler)
  },

  getAllActive: (params = {}) => () => {
    const { $axios } = ctx
    const _params = { statuses: 'customer_confirmed,producing,action_needed', ...buildSearchParams(ctx, params) }

    return $axios.get(getAllPath(ctx), { params: _params }).catch(errorHandler)
  },

  getAllCancelled: (params = {}) => () => {
    const { $axios } = ctx
    const _params = { statuses: 'cancelled', ...buildSearchParams(ctx, params) }

    return $axios.get(getAllPath(ctx), { params: _params }).catch(errorHandler)
  },

  getAllDesigning: (params = {}) => () => {
    const { $axios } = ctx
    const _params = { statuses: 'designing', ...buildSearchParams(ctx, params) }

    return $axios.get(getAllPath(ctx), { params: _params }).catch(errorHandler)
  },

  count: (params) => () => {
    const { $axios } = ctx
    const _params = { statuses: params.statuses, ...buildSearchParams(ctx, params) }

    return $axios.get(getCountPath(ctx), { params: _params })
  },

  create: (prescription) => () => {
    const { $axios } = ctx
    const payload = { patient_id: prescription.patient.id }

    return $axios.post(createPath(ctx), payload)
  },

  show: (id) => () => {
    const { $axios } = ctx
    return $axios.get(getItemPath(id, ctx))
                 .then(response => ({ ...response, data: deserialize(response.data) }))
                 .catch(errorHandler)
  },

  getProductionOrders: (id) => () => {
    const { $axios } = ctx
    return $axios.get(getProductionOrdersPath(id, ctx)).catch(errorHandler)
  },

  getShipmentTrackings: (id) => () => {
    const { $axios } = ctx
    return $axios.get(getShipmentTrackingsPath(id, ctx)).catch(errorHandler)
  },

  getQuotation: (prescription) => () => {
    const { $axios } = ctx
    return $axios.get(getQuotationPath(prescription.id, ctx)).catch(errorHandler)
  },

  save: (prescription) => () => {
    const { $axios } = ctx
    const payload = { raw_data: sanitizedPrescription(prescription) }

    return $axios.put(updatePath(prescription.id, ctx), payload)
  },

  createQuotation: (prescription) => () => {
    const { $axios } = ctx
    const payload = {}

    return $axios.post(createQuotationPath(prescription.id, ctx), payload)
  },

  createOrder: (prescription, salesConditionId) => () => {
    const { $axios } = ctx
    const payload = { sales_condition_id: salesConditionId }

    return $axios.post(createOrderPath(prescription.id, ctx), payload)
  },

  discardQuotation: (prescription) => () => {
    const { $axios } = ctx
    return $axios.post(discardQuotationPath(prescription.id, ctx))
  },

  cancel: ({ id }) => () => {
    const { $axios } = ctx
    return $axios.put(cancelPath(id, ctx))
  },

  duplicate: ({ id }) => () => {
    const { $axios } = ctx
    return $axios.post(duplicatePath(id, ctx))
  },

  editActive: ({ id }) => () => {
    const { $axios } = ctx
    return $axios.post(editActivePath(id, ctx))
  },

  startProductsDesign: ({ id }) => () => {
    const { $axios } = ctx
    return $axios.put(startProductsDesignPath(id, ctx))
  },

  enablePrescriptionEditingByDesigners: ({ id }) => () => {
    const { $axios } = ctx
    return $axios.post(enablePrescriptionEditingByDesignersPath(id, ctx))
  },

  getProductsDesign: ({ id }) => () => {
    const { $axios } = ctx
    return $axios.get(getProductsDesignPath(id, ctx))
  },

  destroy: ({ id }) => () => {
    const { $axios } = ctx
    return $axios.delete(getItemPath(id, ctx))
  }
})
