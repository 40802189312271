import { onGlobalSetup, useContext, useRouter } from '@nuxtjs/composition-api'
import useAuthChannel from '~/services/websocketConnections/channels/auth'

export default () => {
  onGlobalSetup(() => {
    const ctx = useContext()
    const { $auth, localePath } = ctx
    const router = useRouter()

    if($auth.user) {
      useAuthChannel(ctx, $auth.user.id, {
        onLogout() {
          const notLoggedPath = localePath('/al/not_logged')
          $auth.logout().then(() => router.push(notLoggedPath))
        }
      })
    }
  })
}
