export default rawReponse => ({
  ...rawReponse.configuration,
  billing_customer: rawReponse.billing_customer,
  id: rawReponse.id,
  patient: { ...rawReponse.patient },
  status: rawReponse.status,
  planned_delivery_date: rawReponse.planned_delivery_date,
  sales_order_number: rawReponse.sales_order_number,
  quote_total_amount_with_vat: rawReponse.quote_total_amount_with_vat,
  created_at: rawReponse.created_at,
  confirmed_at: rawReponse.confirmed_at
})
