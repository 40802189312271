import ActionCable from 'actioncable'

const nullConsumer = {
  subscriptions: {
    create: (_channel, handlers) => null
  }
}

let connection

export const getConsumer = (ctx) => {
  if(!connection) {
    const { $config } = ctx
    connection = $config.actionCableUrl ? ActionCable.createConsumer($config.actionCableUrl) : nullConsumer
  }

  return connection
}