export default () => {
  const localStorage = window.localStorage

  return {
    setItem(key, value) {
      return localStorage.setItem(key, value)
    },
  
    getItem(key) {
      return localStorage.getItem(key)
    },
  
    existItem(key) {
      return this.getItem(key) !== null && this.getItem(key) !== undefined
    }
  }
}