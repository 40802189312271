import merge from 'lodash.merge'

// NB: translations are imported from namespaces in this order, so the last one overrides all the other
// if keys are duplicated
const fileNamespaces = ['default', 'configuratorFormOptions']

export const readAndMergeTranslations = (langCode) => {
  try {
    return fileNamespaces.reduce((acc, namespace) => {
      acc = merge(acc, { ...require(`../translations/parsed/${langCode}.${namespace}.json`) })
      return acc
    }, {})
  }
  catch(err) {
    console.error(err) // eslint-disable-line no-console
    return {}
  }
}