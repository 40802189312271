export class PrototypeModelElementsConfiguratorFields {
  constructor(prototypeModel) {
    this.prototypeModel = prototypeModel
  }

  get() {
    switch(this.prototypeModel.segment) {
      case 'describe':
        return this.describeSegmentFields
      case 'analogues':
        return this.#analoguesSegmentFields
      default:
        return {}
    }
  }

  allKeys() {
    return Object.keys(this.#allFields)
  }

  allVisibleKeys() {
    return this.allKeys().filter(key => !this.#allFields[key].autocomplete)
  }

  get #allFields() {
    return { ...this.describeSegmentFields, ...this.#analoguesSegmentFields }
  }
  
  get describeSegmentFields() {
    return {
      sistematica: {},
      impianto: {},
      piattaforma: {},
      collo: {}
    }
  }

  get #analoguesSegmentFields() {
    return {
      tipo_analogo: {},
      codice_analogo: {},
      // Viene usata la proprietà `includi_analogo: true|false`
      // aggiungi_analogo: {},
      includere_pin: {},
      // codice_analogo_ov: { autocomplete: true },
      // codice_pin: { autocomplete: true }
    }
  }
}
