import { useContext } from '@nuxtjs/composition-api'
import localStorageProvider from '~/services/localStorageProvider'

const LocalStorage = localStorageProvider()

const FAVOURITE_USER_LANG_PREFIX = 'favouriteUserLang'

const getFavouriteUserLangStoreKey = user => {
  const userId = getUserIdentifier(user)
  return [FAVOURITE_USER_LANG_PREFIX, userId].join(':')
}

const getUserIdentifier = user => user.id || user.code

const getUserLang = user => user.lang.toLowerCase()

const isFavouriteLangSettingSet = user => {
  const key = getFavouriteUserLangStoreKey(user)
  return LocalStorage.existItem(key)
}

const setFavouriteLangSetting = (user, lang) => {
  const key = getFavouriteUserLangStoreKey(user)
  return LocalStorage.setItem(key, lang)
}

const getFavouriteLangSetting = user => {
  const key = getFavouriteUserLangStoreKey(user)
  return LocalStorage.getItem(key)
}

export const setLang = (i18n, dayjs, user) => (userData) => {
  if(isFavouriteLangSettingSet(user)) {
    const existingFavouriteLang = getFavouriteLangSetting(user)
    setFavouriteLang(i18n, dayjs, user)(existingFavouriteLang)
  }
  else {
    const lang = getUserLang(userData) || i18n.defaultLocale
    setFavouriteLangSetting(user, lang)
    i18n.setLocale(lang)
    dayjs.locale(lang)
  }
}

export const setFavouriteLang = (i18n, dayjs, user) => (lang) => {
  setFavouriteLangSetting(user, lang)
  i18n.setLocale(lang)
  dayjs.locale(lang)
}

export default () => {
  const { i18n, $dayjs, $auth } = useContext()
  const loggedUser = $auth.$state.user

  return {
    setLang: setLang(i18n, $dayjs, loggedUser),
    setFavouriteLang: setFavouriteLang(i18n, $dayjs, loggedUser),
    getFavouriteLangSetting: () => getFavouriteLangSetting(loggedUser)
  }
}