import { getConsumer } from '../connection'

const CHANNEL_NAME = 'AuthChannel'

const handleReceived = (message, { onLogout }) => {
  switch(message) {
    case 'logout':
      if(onLogout)
        onLogout()
      break
    default:
      break
  }
}

export default (ctx, userId, propsHandlers = {}) => {
  const handlers = {
    received: (message) => handleReceived(message, propsHandlers)
  }

  getConsumer(ctx).subscriptions.create({ channel: CHANNEL_NAME, id: userId }, { ...handlers })
}
