import { protesicaInternalKey, tipologiaInternalKey } from './workingUnit'

export class ProductConfiguratorFields {
  constructor(workingUnit) {
    this.workingUnit = workingUnit
  }

  get() {
    const { FIELDS_DESCRIPTOR } = this.#workingUnitComponent()
    return FIELDS_DESCRIPTOR
  }

  allVisibleKeys() {
    const allFields = this.get()
    return Object.keys(allFields).filter(key => !allFields[key].autocomplete)
  }

  #WORKING_UNITS_COMPONENTS = {
    supporto_implantare: {
      corona_conometrica: require('../SupportoImplantare/CoronaConometrica.js'),
      corona_avvitata: require('../SupportoImplantare/CoronaAvvitata.js'),
      abutment: require('../SupportoImplantare/Abutment.js'),
      corona_cementata: require('../DentePreparato/CoronaCementata.js'),
      multipla_conometrica: require('../SupportoImplantare/MultiplaConometrica.js'),
      barra: require('../SupportoImplantare/Barra.js'),
      direct_toronto: require('../SupportoImplantare/DirectToronto.js'),
    },
    dente_preparato: {
      corona_cementata: require('../DentePreparato/CoronaCementata.js'),
      multipla_cementata: require('../DentePreparato/MultiplaCementata.js'),
    },
    prototype_model: {
      configuration: require('../PrototypeModel/Configuration.js')
    }
  }

  #workingUnitComponent() {
    if (this.workingUnit.type === 'prototype_model') {
      return this.#WORKING_UNITS_COMPONENTS.prototype_model.configuration
    }
    else {
      const tipologiaKey = tipologiaInternalKey(this.workingUnit.header.tipologia)
      const protesicaKey = protesicaInternalKey(this.workingUnit.header.protesica)
  
      return this.#WORKING_UNITS_COMPONENTS[tipologiaKey][protesicaKey]
    }
  }
}

const FORM_TYPE_FIELDS = {
  productConfigurator: ProductConfiguratorFields
}

export default formType => FORM_TYPE_FIELDS[formType]
