import { findPrototypeModel } from './prototypeModel'

const STEPS = [
  {
    key: 'heading',
    status: 'current',
    selected: true,
    clickable: false,
    visible: true,
    group: 'prescription'
  },
  {
    key: 'oral_cavity_description',
    status: 'upcoming',
    selected: false,
    clickable: false,
    visible: true,
    group: 'prescription'
  },
  {
    key: 'products_configurator',
    status: 'upcoming',
    selected: false,
    clickable: false,
    visible: false,
    group: 'prescription'
  },
  {
    key: 'products_design',
    status: 'upcoming',
    selected: false,
    clickable: false,
    visible: false,
    group: 'design'
  },
  {
    key: 'prototype_model_ask',
    status: 'upcoming',
    selected: false,
    clickable: false,
    visible: false,
    group: 'prototype_model'
  },
  {
    key: 'analogues_configurator',
    status: 'upcoming',
    selected: false,
    clickable: false,
    visible: false,
    group: 'prototype_model'
  },
  {
    key: 'prototype_model_configurator',
    status: 'upcoming',
    selected: false,
    clickable: false,
    visible: false,
    group: 'prototype_model'
  },
  {
    key: 'shipment',
    status: 'upcoming',
    selected: false,
    clickable: false,
    visible: true,
    group: 'ending'
  },
  {
    key: 'summary',
    status: 'upcoming',
    selected: false,
    clickable: false,
    visible: true,
    group: 'ending'
  },
  {
    key: 'confirm',
    status: 'upcoming',
    selected: false,
    clickable: false,
    visible: true,
    group: 'ending'
  }
]

export default {
  getDefaults: () => [...STEPS].map(step => ({ ...step })),

  stepsBeforeProductsDesign: () => {
    const idx = STEPS.findIndex(step => step.key === 'products_design')
    return new Array(idx).fill().map((_, i) => STEPS[i])
  },

  stepsVisibilityForPrescription: prescription => {
    const visibleStepKeys = ['heading', 'oral_cavity_description', 'shipment', 'summary', 'confirm']
    const prototypeModel = findPrototypeModel(prescription)

    if (['self_made_designed', 'digital_assets', 'physical_assets'].includes(prescription.selected_flow)) {
      visibleStepKeys.push('products_configurator')

      if (!prototypeModel) {
        visibleStepKeys.push('prototype_model_ask')
      }
    }
    
    if (['digital_assets', 'physical_assets'].includes(prescription.selected_flow)) {
      visibleStepKeys.push('products_design')
    }

    if (prescription.selected_flow === 'only_prototype_model' || prototypeModel) {
      visibleStepKeys.push('analogues_configurator', 'prototype_model_configurator')
    }

    return STEPS.map(step => ({
      key: step.key,
      visible: visibleStepKeys.includes(step.key)
    }))
  },

  isStepClickable: (stepKey, prescription) => {
    const canEditPrescription = prescription.status === 'draft'
    const hasRequestedQuote = prescription.status === 'quoted'
    const isShipmentKey = stepKey === 'shipment'
    const isPrototypeModelKey = [
      'prototype_model_ask',
      'analogues_configurator',
      'prototype_model_configurator'
    ].includes(stepKey)

    return canEditPrescription ||
      (isShipmentKey && !hasRequestedQuote) ||
      (isPrototypeModelKey && !hasRequestedQuote)
  },

  shouldSendPlasterModel: flow => flow === 'physical_assets',

  hasPrototypeModel: flow => flow === 'only_prototype_model',

  canDiscardPrototypeModel: (prescription, selectedStep) => (
    prescription.selected_flow !== 'only_prototype_model' && !!findPrototypeModel(prescription)
      && ['analogues_configurator', 'prototype_model_configurator'].includes(selectedStep.key)
  )
}
