//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, toRefs, computed } from '@nuxtjs/composition-api'

const SHAPE_CLASSES = {
  default: 'rounded-md',
  circle: 'rounded-full',
  rounded: 'rounded-full'
}

const SIZES_CLASSES = {
  default: {
    small: 'px-2 py-2 text-sm',
    normal: 'px-4 py-2 text-sm',
    large: 'px-5 py-3 text-base'
  },
  circle: {
    small: 'p-1.5 text-sm',
    normal: 'p-3 text-sm'
  },
  rounded: {
    small: 'px-2 py-2 text-sm',
  }
}

const COLOR_CLASSES = {
  primary: 'shadow-sm text-white bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
  'primary-outline': 'shadow-sm text-primary-700 bg-transparent border-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
  'primary-light': 'shadow-sm border border-primary-300 text-primary-600 bg-primary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-300',
  danger: 'shadow-sm text-white bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500',
  'danger-outline': 'shadow-sm text-red-600 bg-transparent border-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500',
  'danger-light': 'shadow-sm text-red-600 bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-300 border-red-300',
  success: 'shadow-sm text-white bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500',
  'warning-light': 'shadow-sm border border-amber-300 text-amber-700 bg-amber-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-300',
  transparent: 'text-gray-700 focus:outline-none focus:ring-1 focus:ring-gray-300 underline',
  'primary-transparent': 'text-primary-700 focus:outline-none focus:ring-1 focus:ring-primary-300 underline',
  'danger-transparent': 'text-red-700 focus:outline-none focus:ring-1 focus:ring-red-300 underline',
  'success-transparent': 'text-green-700 focus:outline-none focus:ring-1 focus:ring-green-300 underline',
  'info-transparent': 'text-blue-700 focus:outline-none focus:ring-1 focus:ring-blue-300 underline',
  gray: 'shadow-sm text-white bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500',
  'gray-outline': 'shadow-sm text-gray-700 bg-transparent border-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400',
  info: 'shadow-sm text-white bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400',
  'info-light': 'shadow-sm text-blue-600 bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300 border-blue-300',
  indigo: 'shadow-sm text-white bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400',
  white: 'shadow-sm text-gray-600 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 border-gray-300'
}

const HOVER_MUTATIONS = {
  primary: 'hover:bg-primary-700',
  'primary-outline': 'hover:bg-primary-100',
  'primary-light': 'hover:bg-primary-300 hover:text-primary-700',
  danger: 'hover:bg-red-700',
  'danger-outline': 'hover:bg-red-200 hover:text-red-700',
  'danger-light': 'hover:bg-red-200 hover:text-red-700',
  success: 'hover:bg-green-700',
  'warning-light': 'hover:bg-amber-200 hover:text-amber-800',
  transparent: 'hover:bg-gray-100',
  'primary-transparent': 'hover:bg-primary-100',
  'danger-transparent': 'hover:bg-red-100',
  'success-transparent': 'hover:bg-green-100',
  'info-transparent': 'hover:bg-blue-100',
  gray: 'hover:bg-gray-500',
  info: 'hover:bg-blue-500',
  indigo: 'hover:bg-indigo-500',
  'info-light': 'hover:bg-blue-200',
  white: 'hover:bg-gray-50'
}

export default defineComponent({
  name: 'Button',
  props: {
    color: {
      type: String,
      default: () => 'primary'
    },
    size: {
      type: String,
      default: () => 'normal'
    },
    shape: {
      type: String,
      default: () => 'default'
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    as: {
      type: String,
      default: () => 'button'
    }
  },
  setup(props) {
    const { color, shape, size } = toRefs(props)

    const buttonColor = computed(() => COLOR_CLASSES[color.value])
    const buttonShape = computed(() => SHAPE_CLASSES[shape.value])
    const buttonSize = computed(() => SIZES_CLASSES[shape.value][size.value])
    const hoverMutation = computed(() => HOVER_MUTATIONS[color.value])

    return { buttonSize, buttonColor, buttonShape, hoverMutation }
  }
})
