//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, defineComponent } from '@nuxtjs/composition-api'

const R_SIZE_MAP = {
  normal: 30,
  small: 15,
  tiny: 10
}

const CXY_SIZE_MAP = {
  normal: 40,
  small: 20,
  tiny: 15
}

const WH_SIZE_MAP = {
  normal: {
    width: '80',
    height: '80'
  },
  small: {
    width: '40',
    height: '40'
  },
  tiny: {
    width: '28',
    height: '28'
  }
}

const COLORS_MAP = {
  primary: 'text-primary-500',
  info: 'text-blue-500'
}

export default defineComponent({
  name: 'CircularProgress',
  props: {
    percent: {
      type: Number,
      required: true
    },
    size: {
      type: String,
      default: () => 'normal'
    },
    color: {
      type: String,
      default: () => 'primary'
    },
    totalTarget: {
      type: Number,
      default: () => 100
    }
  },
  setup(props) {
    const r = R_SIZE_MAP[props.size]
    const cxy = CXY_SIZE_MAP[props.size]
    const circumference = r * 2 * Math.PI
    const wh = WH_SIZE_MAP[props.size]
    const colorClasses = COLORS_MAP[props.color]
    
    const safeTotalTarget = computed(() => {
      if (props.totalTarget <= 0) return 100

      return props.totalTarget
    })

    return {
      r,
      cxy,
      wh,
      circumference,
      colorClasses,
      safeTotalTarget
    }
  },
})
