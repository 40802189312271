// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/AkkRg_Pro_1.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../fonts/AkkIt_Pro_1.otf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../fonts/AkkBd_Pro_1.otf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../fonts/AkkBdIt_Pro_1.otf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../fonts/AkkLg_Pro_1.otf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../fonts/AkkLgIt_Pro_1.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:AkkuratPro;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"opentype\");font-weight:400;font-style:normal}@font-face{font-family:AkkuratPro;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"opentype\");font-weight:400;font-style:italic}@font-face{font-family:AkkuratPro;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"opentype\");font-weight:700;font-style:normal}@font-face{font-family:AkkuratPro;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"opentype\");font-weight:700;font-style:italic}@font-face{font-family:AkkuratPro;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"opentype\");font-weight:300;font-style:normal}@font-face{font-family:AkkuratPro;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"opentype\");font-weight:300;font-style:italic}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
