//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    position: {
      type: String,
      default: () => 'top right'
    },
    width: {
      type: String,
      default: () => '400'
    }
  },
  setup() {},
})
