//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, defineComponent, useContext, ref, useRoute, useRouter, onMounted } from '@nuxtjs/composition-api'
import usePrescriptionsRepository from '@/repositories/prescriptions'

const navigation = [
  {
    key: 'homepage',
    name: 'index',
    label_key: 'appNavbar.navigation.homepage',
    link: '/'
  },
  {
    key: 'prescriptions',
    label_key: 'appNavbar.navigation.prescriptions',
    link: '/prescriptions?section=active'
  },
  {
    key: 'products_designs',
    label_key: 'appNavbar.navigation.products_designs',
    link: '/products_designs'
  },
  {
    key: 'workgroups',
    label_key: 'appNavbar.navigation.workgroups',
    link: '/workgroups'
  },
  // {
  //   key: 'news_center',
  //   label_key: 'appNavbar.navigation.news_center',
  //   link: '/news'
  // },
  {
    key: 'cad_cam_libraries',
    label_key: 'appNavbar.navigation.cad_cam_libraries',
    link: '/cad_cam_libraries'
  },
  {
    key: 'new_prescription',
    link: 'prescriptions/new',
    hidden: true
  },
  {
    key: 'edit_prescription',
    link: 'edit',
    hidden: true
  }
]

const userNavigation = [
  // {
  //   key: 'patients',
  //   label_key: 'appNavbar.userNavigation.user_patients',
  //   link: '/profile/patients',
  //   icon: ['fas', 'users']
  // },
  // {
  //   key: 'profile',
  //   label_key: 'appNavbar.userNavigation.profile',
  //   link: '/profile',
  //   icon: ['fas', 'user-circle']
  // }
]

const navigationItemFinder = (currentPath, currentRouteName) => ({ link, name }) => {
  const linkWithoutQuery = link.split('?')[0]
  return currentPath.endsWith(linkWithoutQuery) || currentRouteName.includes(name)
}

export default defineComponent({
  setup() {
    const ctx = useContext()
    const user = computed(() => ({ ...ctx.$auth.user, full_name: ctx.$auth.user.fullname }))

    const route = useRoute()
    const currentNavigationItem = computed(() => {
      const pathWithoutTrailingSlash = route.value.path.endsWith('/')
        ? route.value.path.substring(0, route.value.path.length - 1) : route.value.path

      const selectedNavItem = navigation.find(navigationItemFinder(pathWithoutTrailingSlash, route.value.name))
                              || userNavigation.find(navigationItemFinder(pathWithoutTrailingSlash, route.value.name))
      return selectedNavItem ? selectedNavItem.key : null
    })    

    const userNavOpen = ref(false)
    const toggleUserNavOpen = () => { userNavOpen.value = !userNavOpen.value }
    const closeUserNav = () => { userNavOpen.value = false }

    const mobileNavigationOpen = ref(false)
    const toggleMobileNavigationOpen = () => { mobileNavigationOpen.value = ! mobileNavigationOpen.value }

    // manual navigate to link in mobile mode to force close the navbar  
    const router = useRouter()
    const onMobileLinkClick = (evt) => {
      evt.preventDefault()
      const relativePath = evt.target.href.replace(evt.target.origin, '')
      router.push(relativePath)
      toggleMobileNavigationOpen()
    }

    const goToNewPrescription = () => { router.push(ctx.localePath('/prescriptions/new')) }

    const showNewPrescriptionButton = computed(() => 
      currentNavigationItem.value !== 'new_prescription' && currentNavigationItem.value !== 'edit_prescription'
    )

    const onAssistanceCenterClick = () => {
      router.push(ctx.localePath('/assistance_center'))
    }
    const isAssistanceRoute = computed(() => route.value.path.includes('assistance_center'))

    const navigationIcons = ref({})
    const updateNavigationIcons = (navigationKey, navigationIcon) => {
      navigationIcons.value = {
        ...navigationIcons.value,
        [navigationKey]: navigationIcon
      }
    }

    const prescriptionsRepo = usePrescriptionsRepository(ctx)
    const fetchPrescriptionsCountAndSetIcon = () => {
      prescriptionsRepo.count({ statuses: 'action_needed' })()
                       .then(({ data: count }) => {
                         if (count && count > 0) {
                           updateNavigationIcons('prescriptions', {
                             icon: ['fas', 'exclamation-triangle'],
                             class: 'h-3.5 w-3.5 text-amber-600 block'
                           })
                         }
                         else {
                           updateNavigationIcons('prescriptions', null)
                         }
                       })
    }

    // TODO: make me generic and configurable
    onMounted(fetchPrescriptionsCountAndSetIcon)

    return {
      navigation: navigation.filter(({ hidden }) => hidden !== true),

      user,
      currentNavigationItem,
      userNavigation,
      userNavOpen,
      toggleUserNavOpen,
      closeUserNav,

      mobileNavigationOpen,
      toggleMobileNavigationOpen,
      onMobileLinkClick,

      showNewPrescriptionButton,
      goToNewPrescription,

      onAssistanceCenterClick,
      isAssistanceRoute,
      navigationIcons
    }
  },
})
