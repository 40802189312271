export default function ({ store, next, route }) {
  const currentPrescriptionInEditing = store.getters['edit_prescription/current'] || {}
  if (currentPrescriptionInEditing.id) {
    if (store.getters['edit_prescription/canExitFromFlow']) {
      next(true)
    }
    else {
      store.dispatch('modals/open', {
        modalName: 'ModalsExitEditPrescriptionFlow',
        attrs: { redirectPath: route.path }
      })
      next(false)
    }
  }
}
